import { visaImages } from '../services/impImages';
import '../scss/service.scss';
import Tooltip from '@mui/material/Tooltip';
import { Link,useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Slider from 'react-slick';
import SocialButton from '../components/SocialButton';

function ServicePage() {

  let navigate=useNavigate();
  const handleClick =  (e) => navigate('/contact'); //toast("Currently Myvisapriority.com doesn't provide online visa application to "+ e.target.name +", Kindly call us to get receive more information from our Visa Specialists, Please contact us on +971 55 636 8548");
  const slides=[
    {url:'assets/slider-main/bg2.jpg', head:'',title:'',subTitle:''},
  ];
  let mainSettings = {
    infinite: true,
    autoplay:true,
    speed: 1000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 960,
        mainSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        mainSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
   <div className="body-inner">
      <section className="banner-carousel banner-carousel-1 mb-0">
      <div className='serviceHomeText'>
                <h1>Visa</h1>
                <Link to='/' className='homeTextLink'>Home</Link> | Visa
      </div>
        <ToastContainer style={{width:'100%'}}/>
<div className='mainContainerStyles'>
<Slider {...mainSettings}>
        {slides.map((image) => (
                        <img loading="lazy" className='serviceImgContainer' src={image.url} alt='MyVisa Priority' />
                ))}
        </Slider>
</div>
</section>
      <section id="main-container" className="infoContainer pb-2" style={{marginTop:'20px'}}>
      <h3 className="pageTitle" style={{color:'white'}}>Visa Countries</h3>
  <div className="container visaItemBox">
    {visaImages.map((image) => (
        <div className="visaImageBox"  key={image.id}>
        <Tooltip title={image.name.toUpperCase()} className="show">
            <div className="visaItem">
              <img  loading="lazy" name={image.name} src={image.img} alt={image.name} onClick={handleClick}/>
            </div>
      </Tooltip>
        </div>
))}
  </div>
</section>

<section className="infoContainer" style={{background:"darkblue"}}>
  <div className="container">
    <div className="row">
        <div className="col-md-12">
          <div className="subscribe-call-to-acton">
              <h1 className='callUs'>Call Us +971 503096788</h1>
          </div>
        </div>
    </div>
  </div>
</section>
<SocialButton/>
    </div>
  )
}

export default ServicePage