export const visaImages=[
    {
      "id":1,
      "name":"Afghanistan",
      "img":"./images/Afghanistan.png"
  },
  {
    "id":2,
    "name":"Albania",
    "img":"./images/Albania.png"
},
{
    "id":3,
    "name":"Algeria",
    "img":"./images/Algeria.png"
},
{
    "id":4,
    "name":"American samoa",
    "img":"./images/American_samoa.png"
},
{
    "id":5,
    "name":"Andorra",
    "img":"./images/Andorra.png"
},
{
    "id":6,
    "name":"Angola",
    "img":"./images/Angola.png"
},
{
    "id":7,
    "name":"Anguilla",
    "img":"./images/Anguilla.png"
},
{
    "id":8,
    "name":"Antigua and barbuda",
    "img":"./images/Antigua_and_barbuda.png"
},
{
    "id":9,
    "name":"Argentina",
    "img":"./images/Argentina.png"
},
{
    "id":10,
    "name":"Armenia",
    "img":"./images/Armenia.png"
},
{
    "id":11,
    "name":"Aruba",
    "img":"./images/Aruba.png"
},
{
    "id":12,
    "name":"Australia",
    "img":"./images/Australia.png"
},
{
    "id":13,
    "name":"Austria",
    "img":"./images/Austria.png"
},
{
    "id":14,
    "name":"Azerbaijan",
    "img":"./images/Azerbaijan.png"
},
{
    "id":15,
    "name":"Bahamas",
    "img":"./images/Bahamas.png"
},
{
    "id":16,
    "name":"Bahrain",
    "img":"./images/Bahrain.png"
},
{
    "id":17,
    "name":"Bangladesh",
    "img":"./images/Bangladesh.png"
},
{
    "id":18,
    "name":"Barbados",
    "img":"./images/Barbados.png"
},
{
    "id":19,
    "name":"Belarus",
    "img":"./images/Belarus.png"
},
{
    "id":20,
    "name":"Belgium",
    "img":"./images/Belgium.png"
},
{
    "id":21,
    "name":"Belize",
    "img":"./images/Belize.png"
},
{
    "id":22,
    "name":"Benin",
    "img":"./images/Benin.png"
},
{
    "id":23,
    "name":"Bermuda",
    "img":"./images/Bermuda.png"
},
{
    "id":24,
    "name":"Bhutan",
    "img":"./images/Bhutan.png"
},
{
    "id":25,
    "name":"Bolivia",
    "img":"./images/Bolivia.png"
},
{
    "id":26,
    "name":"Bosnia and herzegovina",
    "img":"./images/Bosnia_and_herzegovina.png"
},
{
    "id":27,
    "name":"Botswana",
    "img":"./images/Botswana.png"
},
{
    "id":28,
    "name":"Brazil",
    "img":"./images/Brazil.png"
},
{
    "id":29,
    "name":"British indian ocean territory",
    "img":"./images/British_indian_ocean_territory.png"
},
{
    "id":30,
    "name":"Brunei darussalam",
    "img":"./images/Brunei_darussalam.png"
},
{
    "id":31,
    "name":"Bulgaria",
    "img":"./images/Bulgaria.png"
},
{
    "id":32,
    "name":"Burkina faso",
    "img":"./images/Burkina_faso.png"
},
{
    "id":33,
    "name":"Burundi",
    "img":"./images/Burundi.png"
},
{
    "id":34,
    "name":"Cambodia",
    "img":"./images/Cambodia.png"
},
{
    "id":35,
    "name":"Cameroon",
    "img":"./images/Cameroon.png"
},
{
    "id":36,
    "name":"Canada",
    "img":"./images/Canada.png"
},
{
    "id":37,
    "name":"Cape verde",
    "img":"./images/Cape_verde.png"
},
{
    "id":38,
    "name":"Cayman islands",
    "img":"./images/Cayman_islands.png"
},
{
    "id":39,
    "name":"Central african republic",
    "img":"./images/Central_african_republic.png"
},
{
    "id":40,
    "name":"Chad",
    "img":"./images/Chad.png"
},
{
    "id":41,
    "name":"Chile",
    "img":"./images/Chile.png"
},
{
    "id":42,
    "name":"China",
    "img":"./images/China.png"
},
{
    "id":43,
    "name":"Christmas island",
    "img":"./images/Christmas_island.png"
},
{
    "id":44,
    "name":"Cocos(keeling) islands",
    "img":"./images/Cocos_islands.png"
},  
{
    "id":45,
    "name":"Colombia",
    "img":"./images/Colombia.png"
},
{
    "id":46,
    "name":"Comoros",
    "img":"./images/Comoros.png"
},
{
    "id":47,
    "name":"Congo",
    "img":"./images/Congo.png"
},
{
    "id":48,
    "name":"Congo democratic republic",
    "img":"./images/Congo_democratic_republic.png"
},
{
    "id":49,
    "name":"Cook islands",
    "img":"./images/Cook_islands.png"
},
{
    "id":50,
    "name":"Costa rica",
    "img":"./images/Costa_rica.png"
},
{
    "id":51,
    "name":"Croatia",
    "img":"./images/Croatia.png"
},
{
    "id":52,
    "name":"Cuba",
    "img":"./images/Cuba.png"
},
{
    "id":53,
    "name":"Cyprus",
    "img":"./images/Cyprus.png"
},
{
    "id":54,
    "name":"Czech republic",
    "img":"./images/Czech_republic.png"
},
{
    "id":55,
    "name":"Denmark",
    "img":"./images/Denmark.png"
},
{
    "id":56,
    "name":"Djibouti",
    "img":"./images/Djibouti.png"
},
{
    "id":57,
    "name":"Dominican republic",
    "img":"./images/Dominican_Republic.png"
},
{
    "id":58,
    "name":"Ecuador",
    "img":"./images/Ecuador.png"
},
{
    "id":59,
    "name":"Egypt",
    "img":"./images/Egypt.png"
},
{
    "id":60,
    "name":"El salvador",
    "img":"./images/El_salvador.png"
},
{
    "id":61,
    "name":"Equatorial guinea",
    "img":"./images/Equatorial_guinea.png"
},
{
    "id":62,
    "name":"salvEritreaador",
    "img":"./images/Eritrea.png"
},
{
    "id":63,
    "name":"Estonia",
    "img":"./images/Estonia.png"
},
{
    "id":64,
    "name":"Ethiopia",
    "img":"./images/Ethiopia.png"
},
{
    "id":65,
    "name":"Falkland_islands",
    "img":"./images/Falkland_islands.png"
},
{
    "id":66,
    "name":"Faroe_islands",
    "img":"./images/Faroe_islands.png"
},
{
    "id":67,
    "name":"Fiji",
    "img":"./images/Fiji.png"
},
{
    "id":68,
    "name":"Finland",
    "img":"./images/Finland.png"
},
{
    "id":69,
    "name":"France",
    "img":"./images/France.png"
},
{
    "id":70,
    "name":"French polynesia",
    "img":"./images/French_polynesia.png"
},
{
    "id":71,
    "name":"Gabon",
    "img":"./images/Gabon.png"
},
{
    "id":72,
    "name":"Gambia",
    "img":"./images/Gambia.png"
},
{
    "id":73,
    "name":"Georgia",
    "img":"./images/Georgia.png"
},
{
    "id":74,
    "name":"Germany",
    "img":"./images/Germany.png"
},
{
    "id":75,
    "name":"Ghana",
    "img":"./images/Ghana.png"
},
{
    "id":76,
    "name":"Gibraltar",
    "img":"./images/Gibraltar.png"
},
{
    "id":77,
    "name":"Greece",
    "img":"./images/Greece.png"
},
{
    "id":78,
    "name":"Greenland",
    "img":"./images/Greenland.png"
},
{
    "id":79,
    "name":"Grenada",
    "img":"./images/Grenada.png"
},
{
    "id":80,
    "name":"Guadeloupe",
    "img":"./images/Guadeloupe.png"
},
{
    "id":81,
    "name":"Guam",
    "img":"./images/Guam.png"
},
{
    "id":82,
    "name":"Guatemala",
    "img":"./images/Guatemala.png"
},
{
    "id":83,
    "name":"Guinea",
    "img":"./images/Guinea.png"
},
{
    "id":84,
    "name":"Guinea bissau",
    "img":"./images/Guinea_bissau.png"
},
{
    "id":85,
    "name":"Guyana",
    "img":"./images/Guyana.png"
},
{
    "id":86,
    "name":"Haiti",
    "img":"./images/Haiti.png"
},
{
    "id":87,
    "name":"Honduras",
    "img":"./images/Honduras.png"
},
{
    "id":88,
    "name":"Hong kong",
    "img":"./images/Hong_kong.png"
},
{
    "id":89,
    "name":"Hungary",
    "img":"./images/Hungary.png"
},
{
    "id":90,
    "name":"Iceland",
    "img":"./images/Iceland.png"
},
{
    "id":91,
    "name":"India",
    "img":"./images/India.png"
},
{
    "id":92,
    "name":"Indonesia",
    "img":"./images/Indonesia.png"
},
{
    "id":93,
    "name":"Iran",
    "img":"./images/Iran.png"
},
{
    "id":94,
    "name":"Iraq",
    "img":"./images/Iraq.png"
},
{
    "id":95,
    "name":"Ireland",
    "img":"./images/Ireland.png"
},
{
    "id":96,
    "name":"Israel",
    "img":"./images/Israel.png"
},
{
    "id":97,
    "name":"Italy",
    "img":"./images/Italy.png"
},
{
    "id":98,
    "name":"Ivory coast",
    "img":"./images/Ivory_coast.png"
},
{
    "id":99,
    "name":"Jamaica",
    "img":"./images/Jamaica.png"
},
{
    "id":100,
    "name":"Japan",
    "img":"./images/Japan.png"
},
{
    "id":101,
    "name":"Jordan",
    "img":"./images/Jordan.png"
},
{
    "id":102,
    "name":"Kazakhstan",
    "img":"./images/Kazakhstan.png"
},
{
    "id":103,
    "name":"Kenya",
    "img":"./images/Kenya.png"
},
{
    "id":104,
    "name":"Kiribati",
    "img":"./images/Kiribati.png"
},
{
    "id":105,
    "name":"Kosovo",
    "img":"./images/Kosovo.png"
},
{
    "id":106,
    "name":"Kuwait",
    "img":"./images/Kuwait.png"
},
{
    "id":107,
    "name":"Kyrgyzstan",
    "img":"./images/Kyrgyzstan.png"
},
{
    "id":108,
    "name":"Laos",
    "img":"./images/Laos.png"
},
{
    "id":109,
    "name":"Latvia",
    "img":"./images/Latvia.png"
},
{
    "id":110,
    "name":"Lebanon",
    "img":"./images/Lebanon.png"
},
{
    "id":111,
    "name":"Lesotho",
    "img":"./images/Lesotho.png"
},
{
    "id":112,
    "name":"Liberia",
    "img":"./images/Liberia.png"
},
{
    "id":113,
    "name":"Libyan arab jamahiriya",
    "img":"./images/Libyan_arab_jamahiriya.png"
},
{
    "id":114,
    "name":"Liechtenstein",
    "img":"./images/Liechtenstein.png"
},
{
    "id":115,
    "name":"Lithuania",
    "img":"./images/Lithuania.png"
},
{
    "id":116,
    "name":"Luxembourg",
    "img":"./images/Luxembourg.png"
},
{
    "id":117,
    "name":"Macao",
    "img":"./images/Macao.png"
},
{
    "id":118,
    "name":"Macedonia",
    "img":"./images/Macedonia.png"
},
{
    "id":119,
    "name":"Madagascar",
    "img":"./images/Madagascar.png"
},
{
    "id":120,
    "name":"Malawi",
    "img":"./images/Malawi.png"
},
{
    "id":121,
    "name":"Malaysia",
    "img":"./images/Malaysia.png"
},
{
    "id":122,
    "name":"Maldives",
    "img":"./images/Maldives.png"
},
{
    "id":123,
    "name":"Mali",
    "img":"./images/Mali.png"
},
{
    "id":124,
    "name":"Malta",
    "img":"./images/Malta.png"
},
{
    "id":125,
    "name":"Marshall islands",
    "img":"./images/Marshall_islands.png"
},
{
    "id":126,
    "name":"Martinique",
    "img":"./images/Martinique.png"
},
{
    "id":127,
    "name":"Mauritania",
    "img":"./images/Mauritania.png"
},
{
    "id":128,
    "name":"Mauritius",
    "img":"./images/Mauritius.png"
},
{
    "id":129,
    "name":"Mayotte",
    "img":"./images/Mayotte.png"
},
{
    "id":130,
    "name":"Mexico",
    "img":"./images/Mexico.png"
},
{
    "id":131,
    "name":"Micronesia",
    "img":"./images/Micronesia.png"
},
{
    "id":132,
    "name":"Moldova",
    "img":"./images/Moldova.png"
},
{
    "id":133,
    "name":"Monaco",
    "img":"./images/Monaco.png"
},
{
    "id":134,
    "name":"Mongolia",
    "img":"./images/Mongolia.png"
},
{
    "id":135,
    "name":"Montenegro",
    "img":"./images/Montenegro.png"
},
{
    "id":136,
    "name":"Montserrat",
    "img":"./images/Montserrat.png"
},
{
    "id":137,
    "name":"Morocco",
    "img":"./images/Morocco.png"
},
{
    "id":138,
    "name":"Mozambique",
    "img":"./images/Mozambique.png"
},
{
    "id":139,
    "name":"Myanmar",
    "img":"./images/Myanmar.png"
},
{
    "id":140,
    "name":"Namibia",
    "img":"./images/Namibia.png"
},
{
    "id":141,
    "name":"Nauru",
    "img":"./images/Nauru.png"
},
{
    "id":142,
    "name":"Nepal",
    "img":"./images/Nepal.png"
},
{
    "id":143,
    "name":"Netherlands",
    "img":"./images/Netherlands.png"
},
{
    "id":144,
    "name":"Netherlands antilles",
    "img":"./images/Netherlands_antilles.png"
},
{
    "id":145,
    "name":"New zealand",
    "img":"./images/New_zealand.png"
},
{
    "id":146,
    "name":"New caledonia",
    "img":"./images/New_caledonia.png"
},
{
    "id":147,
    "name":"Nicaragua",
    "img":"./images/Nicaragua.png"
},
{
    "id":148,
    "name":"Niger",
    "img":"./images/Niger.png"
},
{
    "id":149,
    "name":"Nigeria",
    "img":"./images/Nigeria.png"
},
{
    "id":150,
    "name":"Niue",
    "img":"./images/Niue.png"
},
{
    "id":151,
    "name":"Norfolk island",
    "img":"./images/Norfolk_island.png"
},
{
    "id":152,
    "name":"Northern mariana islands",
    "img":"./images/Northern_mariana_islands.png"
},
{
    "id":153,
    "name":"Norway",
    "img":"./images/Norway.png"
},
{
    "id":154,
    "name":"Oman",
    "img":"./images/Oman.png"
},
{
    "id":155,
    "name":"Pakistan",
    "img":"./images/Pakistan.png"
},
{
    "id":156,
    "name":"Palau",
    "img":"./images/Palau.png"
},
{
    "id":157,
    "name":"Palestinian territory occupied",
    "img":"./images/Palestinian_territory_occupied.png"
},
{
    "id":158,
    "name":"Panama",
    "img":"./images/Panama.png"
},
{
    "id":159,
    "name":"Papua new guinea",
    "img":"./images/Papua_new_guinea.png"
},
{
    "id":160,
    "name":"Paraguay",
    "img":"./images/Paraguay.png"
},
{
    "id":161,
    "name":"Peru",
    "img":"./images/Peru.png"
},
{
    "id":162,
    "name":"Philippines",
    "img":"./images/Philippines.png"
},
{
    "id":163,
    "name":"Pitcairn",
    "img":"./images/Pitcairn.png"
},
{
    "id":164,
    "name":"Poland",
    "img":"./images/Poland.png"
},
{
    "id":165,
    "name":"Portugal",
    "img":"./images/Portugal.png"
},
{
    "id":166,
    "name":"Puert rico",
    "img":"./images/Puert_rico.png"
},
{
    "id":167,
    "name":"Qatar",
    "img":"./images/Qatar.png"
},
{
    "id":168,
    "name":"Reunion",
    "img":"./images/Reunion.png"
},
{
    "id":169,
    "name":"Romania",
    "img":"./images/Romania.png"
},
{
    "id":170,
    "name":"Russia",
    "img":"./images/Russia.png"
},
{
    "id":171,
    "name":"Rwanda",
    "img":"./images/Rwanda.png"
},
{
    "id":172,
    "name":"Saint helena",
    "img":"./images/Saint_helena.png"
},
{
    "id":173,
    "name":"Saint kitts and nevis",
    "img":"./images/Saint_kitts_and_nevis.png"
},
{
    "id":174,
    "name":"Saint lucia",
    "img":"./images/Saint_lucia.png"
},
{
    "id":175,
    "name":"Saint martin",
    "img":"./images/Saint_martin.png"
},
{
    "id":176,
    "name":"Saint pierre and miquelon",
    "img":"./images/Saint_pierre_and_miquelon.png"
},
{
    "id":177,
    "name":"Saint vincent and the grenadines",
    "img":"./images/Saint_vincent_and_the_grenadines.png"
},
{
    "id":178,
    "name":"Samoa",
    "img":"./images/Samoa.png"
},
{
    "id":179,
    "name":"San marino",
    "img":"./images/San_marino.png"
},
{
    "id":180,
    "name":"Sao tome and principe",
    "img":"./images/Sao_tome_and_principe.png"
},
{
    "id":181,
    "name":"Saudi arabia",
    "img":"./images/Saudi_arabia.png"
},
{
    "id":182,
    "name":"Senegal",
    "img":"./images/Senegal.png"
},
{
    "id":183,
    "name":"Serbia",
    "img":"./images/Serbia.png"
},
{
    "id":184,
    "name":"Seychelles",
    "img":"./images/Seychelles.png"
},
{
    "id":185,
    "name":"Sierra leone",
    "img":"./images/Sierra_leone.png"
},
{
    "id":186,
    "name":"Singapore",
    "img":"./images/Singapore.png"
},
{
    "id":187,
    "name":"Slovakia",
    "img":"./images/Slovakia.png"
},
{
    "id":188,
    "name":"Slovenia",
    "img":"./images/Slovenia.png"
},
{
    "id":189,
    "name":"Solomon islands",
    "img":"./images/Solomon_islands.png"
},
{
    "id":190,
    "name":"Somalia",
    "img":"./images/Somalia.png"
},
{
    "id":191,
    "name":"South africa",
    "img":"./images/South_africa.png"
},
{
    "id":192,
    "name":"South korea",
    "img":"./images/South_korea.png"
},
{
    "id":193,
    "name":"South sudan",
    "img":"./images/South_sudan.png"
},
{
    "id":194,
    "name":"Spain",
    "img":"./images/Spain.png"
},
{
    "id":195,
    "name":"Sri lanka",
    "img":"./images/Sri_lanka.png"
},
{
    "id":196,
    "name":"Sudan",
    "img":"./images/Sudan.png"
},
{
    "id":197,
    "name":"Suriname",
    "img":"./images/Suriname.png"
},
{
    "id":198,
    "name":"Swaziland",
    "img":"./images/Swaziland.png"
},
{
    "id":199,
    "name":"Sweden",
    "img":"./images/Sweden.png"
},
{
    "id":200,
    "name":"Switzerland",
    "img":"./images/Switzerland.png"
},
{
    "id":201,
    "name":"Taiwan",
    "img":"./images/Taiwan.png"
},
{
    "id":202,
    "name":"Tajikistan",
    "img":"./images/Tajikistan.png"
},
{
    "id":203,
    "name":"Tanzania",
    "img":"./images/Tanzania.png"
},
{
    "id":204,
    "name":"Thailand",
    "img":"./images/Thailand.png"
},
{
    "id":205,
    "name":"Timor leste",
    "img":"./images/Timor_leste.png"
},
{
    "id":206,
    "name":"Togo",
    "img":"./images/Togo.png"
},
{
    "id":207,
    "name":"Tokelau",
    "img":"./images/Tokelau.png"
},
{
    "id":208,
    "name":"Tonga",
    "img":"./images/Tonga.png"
},
{
    "id":209,
    "name":"Trinidad tobago",
    "img":"./images/Trinidad_tobago.png"
},
{
    "id":210,
    "name":"Tunisia",
    "img":"./images/Tunisia.png"
},
{
    "id":211,
    "name":"Turkey",
    "img":"./images/Turkey.png"
},
{
    "id":212,
    "name":"Turkmenistan",
    "img":"./images/Turkmenistan.png"
},
{
    "id":213,
    "name":"Tuvalu",
    "img":"./images/Tuvalu.png"
},
{
    "id":214,
    "name":"Uganda",
    "img":"./images/Uganda.png"
},
{
    "id":215,
    "name":"Ukraine",
    "img":"./images/Ukraine.png"
},
{
    "id":216,
    "name":"United arab emirates",
    "img":"./images/United_arab_emirates.png"
},
{
    "id":217,
    "name":"United kingdom",
    "img":"./images/United_kingdom.png"
},
{
    "id":218,
    "name":"United states of america",
    "img":"./images/United_states_of_america.png"
},
{
    "id":219,
    "name":"Uruguay",
    "img":"./images/Uruguay.png"
},
{
    "id":220,
    "name":"Us virgin island",
    "img":"./images/Us_virgin_island.png"
},
{
    "id":221,
    "name":"Uzbekistan",
    "img":"./images/Uzbekistan.png"
},
{
    "id":222,
    "name":"Vanuatu",
    "img":"./images/Vanuatu.png"
},
{
    "id":223,
    "name":"Vatican",
    "img":"./images/Vatican.png"
},
{
    "id":224,
    "name":"Venezuela",
    "img":"./images/Venezuela.png"
},
{
    "id":225,
    "name":"Vietnam",
    "img":"./images/Vietnam.png"
},
{
    "id":226,
    "name":"Wallis_and_futuna",
    "img":"./images/Wallis_and_futuna.png"
},
{
    "id":227,
    "name":"Yemen",
    "img":"./images/Yemen.png"
},
{
    "id":228,
    "name":"Zambia",
    "img":"./images/Zambia.png"
},
{
    "id":229,
    "name":"Zimbabwe",
    "img":"./images/Zimbabwe.png"
}
];

export const mostVisitedVisas=[
    {
      "id":1,
      "name":"United kingdom",
      "img":"./assets/images/united_kingdom.jpg"
  },
  {
    "id":2,
    "name":"United states of america",
    "img":"./assets/images/united_states_of_amarica.jpg"
},
{
    "id":3,
    "name":"Germany",
    "img":"./assets/images/germany.jpg"
},
{
    "id":4,
    "name":"France",
    "img":"./assets/images/france.jpg"
},
{
    "id":5,
    "name":"Greece",
    "img":"./assets/images/greece.jpg"
},
{
    "id":6,
    "name":"Spain",
    "img":"./assets/images/spain.jpg"
},
{
    "id":7,
    "name":"Italy",
    "img":"./assets/images/Italy.jpg"
},
{
    "id":8,
    "name":"Switzerland",
    "img":"./assets/images/Switzerland.jpg"
},
{
    "id":9,
    "name":"Turkey",
    "img":"./assets/images/Turkey.jpg"
},
{
    "id":10,
    "name":"Russia",
    "img":"./assets/images/Russia.jpg"
},
{
    "id":11,
    "name":"China",
    "img":"./assets/images/China.jpg"
},
{
    "id":12,
    "name":"Egypt",
    "img":"./assets/images/Egypt.jpg"
},
{
    "id":13,
    "name":"Netherlands",
    "img":"./assets/images/Netherlands.jpg"
},
{
    "id":14,
    "name":"Canada",
    "img":"./assets/images/Canada.jpg"
}
];

export const myBlogs=[
    {
      "id":1,
      "name":"Digital Schengen visa application  by EU Commission",
      "img":"./assets/images/blog/digital_schengen.jpg",
      "link":"https://myvisapriority.blogspot.com/p/digital-schengen-visa-application-by-eu.html"
  },
  {
    "id":2,
    "name":"Why travel around the world?",
    "img":"./assets/images/blog/why_travel.jpg",
    "link":"https://myvisapriority.blogspot.com/p/why-travel-around-world.html"
},
{
    "id":3,
    "name":"Schengen Visa Information",
    "img":"./assets/images/blog/schengen_visa.jpg",
    "link":"https://myvisapriority.blogspot.com/p/schengen-visa-information.html"
},
{
    "id":4,
    "name":"Canada Visit Visa Requirements - Tourist & Business Visas",
    "img":"./assets/images/blog/canada_visit.jpg",
    "link":"https://myvisapriority.blogspot.com/p/canada-visit-visa-requirements-tourist.html"
},
{
    "id":5,
    "name":"Schengen Visa Requirements - Business - Category C",
    "img":"./assets/images/blog/schengen_visas.jpg",
    "link":"https://myvisapriority.blogspot.com/p/schengen-visa-requirements-business.html"
},
{
    "id":6,
    "name":"Schengen Visa Requirements - Tourist -Category C",
    "img":"./assets/images/blog/schengen_visas_1.jpg",
    "link":"https://myvisapriority.blogspot.com/p/schengen-visa-requirements-tourist.html"
}
]