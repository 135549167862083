import '../scss/home.scss';
import { mostVisitedVisas, myBlogs } from '../services/impImages';
import { Tooltip } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import SocialButton from '../components/SocialButton';

function HomePage() {
  let navigate=useNavigate();
  const handleClick = (e) => navigate('/contact');//toast("Currently Myvisapriority.com doesn't provide online visa application to " + e.target.name + ", Kindly call us to get receive more information from our Visa Specialists, Please contact us on +971 55 636 8548");

  const slides = [
    { url: 'assets/slider-main/bg4_1.jpg' },
    { url: 'assets/slider-main/bg4_2.jpg' },
    { url: 'assets/slider-main/bg4_3.jpg' }
  ];

  const testimonials = [
    {
      id:1,
      name: "- Mohammed Shefeel",
      content:
        "I applied for UK visa with Myvisa Priority A Truly amazing service with an extremely supportive team that’s right there with you throughout the entire Visa procedure. The interaction is super smooth and the explanations are crystal clear. I would really recommend this hassle free service to everyone."
    },
    {
      id:2,
      name: "- Harisson Paul",
      content:
        "They helps you with all documentations, respond quickly to your queries, check on you before & after your visa appointment and also assist with travelling queries. They do everything hassle-free. I will definitely book my next visa assistance with them only."
    },
    {
      id:3,
      name: "- Ahmed Ibrahim",
      content:
        "Got my schengen visa very smoothly with them, everything was organized and there were constant follow ups to help me out with the process to make sure everything went well. the team is super helpful and I really recommend them to get your schengen visa."
    },
    {
      id:4,
      name: " - Jennifer",
      content:
        "Trusted to deal with, they assisted me in applying my Schengen Visa all throughout the process. In a week I got my Visa. I highly recommend them."
    },
    {
      id:5,
      name: "- Ahmed Ibrahim",
      content:
        "Excellent service. Perfect for my Schengen visa! Prompt response. Courteous and knowledgeable staff, helped answer all of my questions. Will definitely use this service again and highly recommend to everyone!."
    }
  ];

  let mainSettings = {
    infinite: true,
    autoplay:true,
    speed: 1000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 960,
        mainSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        mainSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  let settings = {
    infinite: true,
    autoplay:true,
    speed: 1000,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 2,

    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  

  return (
    <div className="body-inner">
      <section className="banner-carousel banner-carousel-1 mb-0">
        <div className='topHead'>
        <div className='topText'>
          <h6>"A journey of a thousand miles begins with a single step"</h6>
          <h1>One Stop Travel Solution</h1>
          <h1> Visa,</h1>
          <h1>Simplified Process</h1>
          <span className='textLine'><p>MyVisaPriority.com ensures that the clients to get their new adventures on the bucket list,Journey to Explore, Discover their happiness on the travel.</p></span>
        </div>
      </div>
        <ToastContainer style={{ width: '100%' }} />
        <div className='containerStyles'>
        <Slider {...mainSettings}>
        {slides.map((image) => (
                        <img loading="lazy" className='imgContainer' src={image.url} alt='MyVisa Priority' />
                ))}
        </Slider>
        </div>
      </section>

      <div className="gap-40"></div>
      <section id="facts" className="secFact" >
        <div className="container">
          <div className="row secFactsRow">
            <div className="col-md-3 col-sm-6">
              <div className="ts-facts-img secFacts">
                <img loading="lazy" src="./assets/icon-image/27.png" alt="facts-img" />
                <h3 className="tsFactsTitle">Visa | Assistance</h3>
              </div>

            </div>
            <div className="col-md-3 col-sm-6">
              <div className="ts-facts-img secFacts">
                <img loading="lazy" src="./assets/icon-image/27.png" alt="facts-img" />
                <h3 className="tsFactsTitle">Application</h3>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="ts-facts-img secFacts">
                <img loading="lazy" src="./assets/icon-image/28.png" alt="facts-img" />
                <h3 className="tsFactsTitle">Payment</h3>
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="ts-facts-img secFacts">
                <img loading="lazy" src="./assets/icon-image/27.png" alt="facts-img" />
                <h3 className="tsFactsTitle">Get Visa</h3>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section id="ts-features" className="infoContainer">
          <div className='serviceBox'>
            <div className="tsIntro">
              <h2 className="infoSubTitle">Our Services</h2>
            </div>
            <div className="row">
            <div className="col-md-6 globeImage">
                 <img loading="lazy" src="./assets/slider-main/glope.png" className='serviceImg' alt="service-icon" style={{width:'230px'}} />
              </div>
              <div className="col-md-6">
                <div className="ts-service-box d-flex">
                  <div className="ts-service-box-img">
                    <img loading="lazy" src="./assets/icon-image/service-icon4.png" className='serviceImg' alt="service-icon" />
                  </div>
                  <h4 className="subTitle">Guidance on Required Documents</h4>
                </div>
                <div className="ts-service-box d-flex">
                  <div className="ts-service-box-img">
                    <img loading="lazy" src="./assets/icon-image/fact1.png" className='serviceImg' alt="service-icon" />
                  </div>
                  <h4 className="subTitle">Appointments for Submission</h4>
                </div>
                <div className="ts-service-box d-flex">
                  <div className="ts-service-box-img">
                    <img loading="lazy" src="./assets/icon-image/service-icon2.png" className='serviceImg' alt="service-icon" />
                  </div>
                  <h4 className="subTitle">Hassle Free Service</h4>
                </div>
                <div className="ts-service-box d-flex">
                  <div className="ts-service-box-img">
                    <img loading="lazy" src="./assets/icon-image/service-icon3.png" className='serviceImg' alt="service-icon" />
                  </div>
                  <h4 className="subTitle">Assistance in Filling the Form</h4>
                </div>
                <div className="ts-service-box d-flex">
                  <span className="ts-service-box-img">
                    <img loading="lazy" src="./assets/icon-image/fact3.png" className='serviceImg' alt="service-icon" />
                  </span>
                  <h4 className="subTitle">Most updated information</h4>
                </div>
                <div className="ts-service-box d-flex">
                  <div className="ts-service-box-img">
                    <img loading="lazy" src="./assets/icon-image/fact2.png" className='serviceImg' alt="service-icon" />
                  </div>
                  <h4 className="subTitle">Instant Support</h4>
                </div>
              </div>
             
            </div>
          </div>
      </section>

      <section id="facts" className="infoContainer">
      <div className="container">
        <div className='quoteItem'>
      <h2 className="quoteTitle">Testimonials</h2>
      <Slider {...settings}>
          {testimonials.map((testimonial) => (
            <div className="out" key={testimonial.id}>
              <div className="quoteText">
              {testimonial.content}
                  <div className="quoteFooter">{testimonial.name} </div>
              </div>
            </div>
          ))}
        </Slider>
        </div>
        
        </div>
      </section>

      <section className="infoContainer" style={{ backgroundColor: 'darkblue' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="subscribe-call-to-acton">
                <h1 className='homeCallUs'> Call Us +971 503096788</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="infoContainer">
        <div className="container">
              <div className="homeVisaItemBox">
              <h2 className="quoteTitle">The Most Requested Visas</h2>
              <Slider {...settings}>
                {mostVisitedVisas.map((image) => (
                  <div className="col-lg-4 col-md-6 mb-5" key={image.id}>
                    <Tooltip title={image.name.toUpperCase()} className="show">
                      <div className="visaItem">
                        <img loading="lazy" src={image.img} alt={image.name}  onClick={handleClick} />
                      </div>
                    </Tooltip>
                  </div>
                ))}
                 </Slider>
              </div>
        </div>
      </section>
      <section id="facts" className="infoContainer">
      <div className="container">
        <div className='blogItemBox'>
      <h2 className="quoteTitle">From Our Blog</h2>
      <Slider {...settings}>
          {myBlogs.map((myBlog) => (
              <div className="blogBox"  key={myBlog.id}>
                <a  data-no-turbolink="true" target="_blank" style={{textDecoration:'none'}}  rel="noopener noreferrer" href={myBlog.link} >
              <img loading="lazy"  src={myBlog.img} alt={myBlog.name} className='blogImage' />
                  <h5 className="blogTitle">{myBlog.name} </h5> </a>
                  <a className="blogLink blogButton" data-no-turbolink="true" target="_blank"  rel="noopener noreferrer" href={myBlog.link} >Read More</a>
              </div>
          ))}
        </Slider>
        </div>
        
        </div>
      </section>
      <div className='infoContainer'></div>
      <SocialButton/>
    </div>
  )
}

export default HomePage