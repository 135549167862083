import '../scss/navbar.scss';
import { Link } from 'react-router-dom';
import{FaBars,FaTimes,FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import { useState } from 'react';

function NavBar() {
  const [icon,SetIcon]=useState(false);

  const handleClick=()=>{
    SetIcon(!icon);
   };

   const closeSideDrawer=()=>{
    SetIcon(false);
   };

  return (
    <>
    <div className='topper'>
  <ul className='infoBox'>
    <li>
      <a href={`mailto:${'visa@myvisapriority.com'}`}  className='infoText'>
        <FaEnvelope className='infoIcon'/>
        <span id='lbl'> visa@myvisapriority.com</span>
        </a>
    </li>
    <li>
      <a href={`tel:${'+971503096788'}`} className='infoText'>
        <FaPhoneAlt className='infoIcon'/>
        <span id='lbl'> +971 503096788</span>   
        </a>
    </li>
    <li>    
      <a
          href="https://wa.me/+971503096788"
          target="_blank"
          rel="noopener noreferrer"  className="infoText"
        >
        <img src="./assets/icon-image/wappicons.png" alt="MyVisa Priority" className='infoWaIcon'/></a>
    </li>
  </ul>
</div>
    <nav className='navBar'>
    <Link className="navLogo" data-no-turbolink="true" to="/" onClick={closeSideDrawer} > <img src="./assets/logo.png" alt="MyVisa Priority" className='logoImg' /></Link>
    <div className="menuIcon" onClick={handleClick}>
      {icon?<FaTimes className='faTimes'/>:<FaBars className='faBars'/>}
    </div>
      <ul className={icon? 'navMenu active' :'navMenu'}>
        <li>
      <Link className="navLink" data-no-turbolink="true" to="/" onClick={closeSideDrawer}>Home</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/about" onClick={closeSideDrawer}>About Us</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/services" onClick={closeSideDrawer}>Visa</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/contact" onClick={closeSideDrawer}>Contact Us</Link>
      </li>
      <li>
      <Link className="navLink applyNow" data-no-turbolink="true" to="/contact" onClick={closeSideDrawer}>Apply  Now</Link>
      </li>
      </ul>
    </nav>
    </>
  )
}

export default NavBar