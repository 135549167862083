import '../scss/privacy.scss';
import SocialButton from '../components/SocialButton';

function PrivacyPage() {

  return (
    <div  className="body-inner">
      <section id="main-container" className="infoContainer">
  <div className="container privacySecBox">
    <div className="row">
        <div className="col-lg-12 who-we-are">
          <h4 className="secTitle">PRIVACY STATEMENT</h4>
          <p className='boxText'>It is the policy of MYVISA PRIORITY to protect and preserve the privacy of its users and customers, and the confidentiality of the information they provide, subject to conditions described below. To demonstrate our commitment to privacy we encourage all of our current and prospective users to read this Privacy Statement carefully before using the system. </p>
          <p className='boxText'>This privacy statement discloses what information we gather, how we use it, and how to correct or change it. It is our intention to give you as much control as possible over your privacy in regard to your Information. Be assured that we will not disclose personal Information to third parties without your consent. By using our site, you agree to the terms of the Terms and Conditions and our Privacy Statement. MYVISA PRIORITY reserves the right to expand and/or modify this Statement at any time.</p>
          <p className='boxText'><b>1. Security.</b> MYVISA PRIORITY is committed to providing the highest level of security and privacy. All transactions of user authentication including credit cards processing are conducted using SSL (Secure Socket Layer) technology, supported by your browser, which encrypts all information that is sent to us. We take every precaution to protect personal information from loss, misuse, unauthorized access, disclosure, alteration or destruction by implementing policies and procedures to ensure that personal information is kept only for the purposes for which it has been gathered</p>
          <p className='boxText'><b>2. Information.</b>We take measures to ensure that the information you provide is recorded accurately and completely. We provide you with access to your personal information at all times for correcting or modifying that information where appropriate.</p>
          <p className='boxText'><b>Information Collected.</b> from/about Customers is used exclusively for the purpose of providing better service. It is never released to third parties except when expressly permitted by the customer. Any information you provide is completely confidential and will be protected from unauthorized use.</p>
          <p className='boxText'><b>Financial Transactions.</b> Credit card transactions are processed through one of the major third-party credit card processing companies. All your information is encrypted and is used only to complete the appropriate transactions.</p>
          <p className='boxText'><b>Cookies.</b> MYVISA PRIORITY uses cookies to recognize our clients when they visit our site. That allows us to customize their experience on our web site. You may need to have cookies "turned on" or enabled in the browser you use in order to register. But if you choose to disable cookies you still will be able to navigate the site. Cookies do not store any personal information. All the information is entirely confidential and is never sold or seen outside of the company. MYVISA PRIORITY may display links or advertisement of /to other sites and companies that may also use cookies. In such cases, MYVISA PRIORITY cannot be held responsible for any and all information that these parties collect through the use of cookies. You are hereby advised to familiarize yourself with privacy policies and information sharing standards of these sites as they may be different from MYVISA PRIORITY's policies and standards.</p>
          <p className='boxText'><b>System information.</b> MYVISA PRIORITY reserves the right to collect and store such information as IP address, browser type or operating system type. All the information is highly confidential and will be used exclusively for system administration purposes. This information helps diagnose problems, monitor traffic and site usage.</p>
          <p className='boxText'><b>Email.</b>MYVISA PRIORITY uses email to notify our members or clients about changes in the status of their applications, changes in the database, on our web site, or when new services or features are added. As part of the service, MYVISA PRIORITY may send emails notifications whenever there are special discounts, new products added, or new features developed. Our email list is confidential and is never sold or given to third parties.</p>
          <p className='boxText'><b>Change or Modify Your Information.</b>You can change or modify your profile at any time by using your customer ID (login) and password. This approach guaranties safety of your information. Please immediately report any unauthorized use of your customer ID (login) / password or computer equipment. MYVISA PRIORITY is not responsible for any damage, loss or change of information resulting from an unauthorized use of your customer ID (login) /password or computer equipment.</p>
          <p className='boxText'><b> Disclaimer.</b>Communication over the Internet as well as applications used to provide services over the Internet are subject to various security risks. In no event shall MYVISA PRIORITY be responsible for any damages or losses whatsoever, direct or indirect, incidental or consequential, special or punitive, arising from or relating to the unauthorized use, change, deletion or exposure of any information, confidential or not, resulting from unauthorized breaking into the system or any other breach of security, or system failure. MYVISA PRIORITY hereby disclaims all warranties with regard to the hardware and software used to provide security and support this web site including all implied warranties, fitness for a particular purpose and incidental, special, direct or consequential damages. Accordingly, MYVISA PRIORITY, its officers and employees, partners, affiliates, subsidiaries, successors and assigns, and its third-party agents shall not, directly or indirectly, be liable, in any way, to you or any other person for any inaccuracies, misuse, errors, third party interceptions, viruses, or hacker attacks resulting in loss of data or services including, but not limited to errors or interruptions in the transmission or delivery of services. MYVISA PRIORITY contains links to other sites. These links are provided exclusively for information purposes and to assist in locating other Internet resources. Therefore, we are not responsible for the privacy practices or the content of such web sites.</p>
          <p className='boxText'><b> Summary.</b>By accessing this site and using its services, you unconditionally agree with the terms of this Privacy Statement and our Terms & Conditions. You agree to comply with the terms that govern the use of this site and its services and that also govern all information provided by you and other users of MYVISA PRIORITY. If you do not agree to all or any of the terms of this Privacy Statement, please do not use this site.</p>
        </div>
    </div>
  </div>
</section>
<div className='infoContainer'></div>
<SocialButton/>
    </div>
  )
}

export default PrivacyPage
