import '../scss/footer.scss';
import { Link } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";

function FooterPage() {
  return (
        <div className="footer">
          <section>
          <div className="container">
            <div className="row">
            <div className="col-lg-3 col-md-6 globeXImage" style={{marginTop:'10px'}}>
            <img loading="lazy" src="./assets/globe_footer.gif" className='globeImg' alt="service-icon" style={{width:'230px'}} />
            </div>
              <div className="col-lg-3 col-md-6" style={{marginTop:'10px'}}>
                <h4><b><span className="fLogoTitle">MYVISA</span> <span className="fLogoLast">PRIORITY</span> </b> <br/>
                <span className="fLogoSubtitle">Global Visa Services</span></h4>
                <p className="fSubText">
                Fahidi Heights Office #1703B, 1B, 17th Floor,Khalid Bin Al Waleed Road - Bank Street, near Sharaf DG Metro Station, AL Fahidi, Dubai, United Arab Emirates.<br/>
                  Phone: +971 503096788 <br/>
                  Email: visa@myvisapriority.com
                </p>
              </div>
              <div className="col-lg-3 col-md-6" style={{marginTop:'10px'}}>
              <h3 className="fLogoTitle">Quick Links</h3>
                <div className="fSubText">
                 <Link to='/' className='fSubTextLink'>MyVisa Priority</Link>
                 <br/>
                  <Link to='/about' className='fSubTextLink'>About Us</Link> 
                  <br/> 
                  <Link to='/services' className='fSubTextLink'>Visa</Link>
                  <br/> 
                  <Link to='/contact' className='fSubTextLink'>Contact Us</Link> 
                  <br/> 
                  <Link to='/privacy' className='fSubTextLink'>Privacy Policy</Link> 
                  <br/> 
                  <Link to='/termcondition' className='fSubTextLink'>Terms and Condition</Link> 
                </div>
              </div>
              <div className="col-lg-3 col-md-6" style={{marginTop:'10px'}}>
                <h3 className="fLogoTitle">Operating Hours</h3>
                <div className="fSubText">
                  <table>
                    <thead></thead>
                    <tbody>
                    <tr>
                     <td>Monday </td>
                     <td>: 9:00 AM - 6:00 PM</td>
                    </tr>
                    <tr>
                     <td>Tuesday</td>
                     <td>: 9:00 AM - 6:00 PM</td>
                    </tr>
                    <tr>
                     <td> Wednesday</td>
                     <td>: 9:00 AM - 6:00 PM</td>
                    </tr>
                    <tr>
                     <td>Thursday</td>
                     <td>: 9:00 AM - 6:00 PM</td>
                    </tr>
                    <tr>
                     <td>Friday</td>
                     <td>: 9:00 AM - 6:00 PM</td>
                    </tr>
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          </section>

        <section className="copyright" style={{backgroundColor : '#fff' }}>
          <div className="container footerText">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="copyright-info text-center text-md-center">
                  <span>Copyright &copy; 
                     MYVisaPriority{ new Date().getFullYear() }
                    ,  <img src="./assets/sign.png" alt="Ratchus" className='myIcon'/>  Powered by Ratchus</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ScrollToTop smooth style={{backgroundColor:'lightblue', color:'blue'}}/>
        </div>
  )
}

export default FooterPage