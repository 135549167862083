import { useState,useRef,useEffect } from "react";
import { Wave } from 'react-animated-text';
import { Random } from 'react-animated-text';

const delay = 2500;
const ImageSlider=({slides})=>{
    const[currentIndex,setCurrentIndex]=useState(0);
    const timeoutRef = useRef(null);

    const sliderStyles={
        height:'100%',
        position:'relative'
    };

    const slideStyles={
        width:'100%',
        height:'100%',
        borderRadius:'5px',
        backgroundPosition:'center',
        backgroundSize:'cover',
        backgroundImage:`url(${slides[currentIndex].url})`
    };

    const leftArrowStyles={
        position:'absolute',
        top:'50%',
        tranform:'translate(0,-50%)',
        left:'32px',
        fontSize:'45px',
        color:'#fff',
        zIndex: 1,
        cursor:'pointer',
    };
    const subTitleStyles={
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        tranform:'translate(0,-50%)',
        fontSize:'30px',
        color:'darkblue',
        zIndex: 1
    };

    const titleStyles={
        display: 'flex',
        justifyContent: 'center',
        marginTop:'150px',
        tranform:'translate(0,-50%)',
        fontSize:'40px',
        color:'white',
        zIndex: 1
    };

    const headStyles={
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        tranform:'translate(0,-50%)',
        fontSize:'25px',
        color:'goldenrod',
        paddingTop: '200px',
        zIndex: 1
    };

    const rightArrowStyles={
        position:'absolute',
        top:'50%',
        tranform:'translate(0,-50%)',
        right:'32px',
        fontSize:'45px',
        color:'#fff',
        zIndex: 1,
        cursor:'pointer',
    };

   /* const goToPrevious=()=>{
        const isFirstSlide=currentIndex===0;
        const newIndex=isFirstSlide ? slides.legnth-1 :currentIndex-1;
        if(newIndex>=0){
        setCurrentIndex(newIndex);
       }
    };

    const goToNext=()=>{
        const isLastSlide=currentIndex===slides.legnth - 1;
        const newIndex=isLastSlide ? 0 :currentIndex + 1;
        if(newIndex<3){
         setCurrentIndex(newIndex);
        }
    } */

    function resetTimeout() {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      }
    
      useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
          () =>
          setCurrentIndex((prevIndex) =>
              prevIndex === slides.length - 1 ? 0 : prevIndex + 1
            ),
          delay
        );
    
        return () => {
          resetTimeout();
        };
      }, [currentIndex,slides]);
    

    return <div style={sliderStyles}>
        <div style={leftArrowStyles}></div>
        <div style={rightArrowStyles}></div>
        <div style={slideStyles}> 
        <h5 style={headStyles}> 
        <Random text={`${slides[currentIndex].head}`} effect="stretch" direction="travel" effectChange={1.2}/>
        </h5>
        <h1 style={titleStyles}> 
        <Wave text={`${slides[currentIndex].title}`} effect="stretch" effectChange={2.2}/>
        </h1>
        <p style={subTitleStyles}>
        <Random text={`${slides[currentIndex].subTitle}`}  effect="verticalFadeOut" effectDirection="right" effectChange={15.0} />
        </p>
</div>
    </div>
};

export default ImageSlider; 