import '../scss/about.scss';
import { Link } from 'react-router-dom';
import ImageSlider from '../ImageSlider';
import Slider from 'react-slick';
import SocialButton from '../components/SocialButton';

function AboutPage() {

  /* const myFunction=()=> {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");
  
    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more"; 
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less"; 
      moreText.style.display = "inline";
    }
  }
  
  const myFunction1 =() => {
    var dots = document.getElementById("dots1");
    var moreText = document.getElementById("more1");
    var btnText = document.getElementById("myBtn1");
  
    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more"; 
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less"; 
      moreText.style.display = "inline";
    }
  }
  
  const myFunction2=()=> {
    var dots = document.getElementById("dots2");
    var moreText = document.getElementById("more2");
    var btnText = document.getElementById("myBtn2");
  
    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more"; 
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less"; 
      moreText.style.display = "inline";
    }
  }
  const myFunction3=()=> {
    var dots = document.getElementById("dots3");
    var moreText = document.getElementById("more3");
    var btnText = document.getElementById("myBtn3");
  
    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more"; 
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less"; 
      moreText.style.display = "inline";
    }
  } */

  const slides=[
    {url:'assets/slider-pages/img3.jpg', head:'',title:'',subTitle:''},
    {url:'assets/slider-pages/img2.jpg', head:'',title:'',subTitle:''},
    {url:'assets/slider-pages/img4.jpg', head:'',title:'',subTitle:''},
    {url:'assets/slider-pages/img5.jpg', head:'',title:'',subTitle:''}
  ];

  let mainSettings = {
    infinite: true,
    autoplay:true,
    speed: 1000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 960,
        mainSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        mainSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const mainSlides=[
    {url:'assets/slider-main/bg1.jpg', head:'',title:'',subTitle:''},
  ];

const containerStyles={
  width:'100%',
  margin:'0 auto',
  objectFit: 'cover'
}

  return (
    <div  className="body-inner">
      <section className="banner-carousel banner-carousel-1 mb-0">
      <div className='aboutHomeText'>
                <h1>About Us</h1>
                <Link to='/' className='homeTextLink'>Home</Link> | About Us
      </div>
       <div className='mainContainerStyles'>
            <Slider {...mainSettings}>
        {mainSlides.map((image) => (
                        <img loading="lazy" className='aboutImgContainer' src={image.url} alt='MyVisa Priority' />
                ))}
        </Slider>
          </div>
      </section>
  <section id="main-container" className="infoContainer">
  <div className="container aboutSecBox">
  <div className="row text-center">
        <div className="col-lg-12">
          <h2 className="secMidTitle">We Are MyVisaPriority</h2>
        </div>
    </div>
    <div className="row">
        <div className="col-lg-6">
          <p className='aboutBoxText'>www.myvisapriority.com is, Comprises a highly qualified and dedicated team, wholly committed to providing effective and efficient visa information and application service to commercial and private applicants. Our commitment to efficiency, accuracy, and detail has won the attention of a number of established travel companies and we continue to enjoy preferred visa supplier status with a significant proportion of the current market leaders in the travel industry. </p>
          <p className='aboutBoxText'>My Visa Priority is a travel visa services company that offers the best of everything. We provide a simple greeting that can lead to the most gorgeous cultural or multi-sensory experience, passing through everything the visa services to offer.</p>
        </div>
        <div className="col-lg-6 mt-5 mt-lg-0">
          <div style={containerStyles}>
             <ImageSlider slides={slides}/>
          </div>    
        </div>
    </div>
  </div>
</section>

<section id="info-container" className="infoContainer">
  <div className="container serviceMidleBox">
    <div className="row">
        <div className="col-lg-3 col-md-6 mb-5">
        <div className="tsServiceBox">
            <div className="iconStyle">
              <img loading="lazy"  src="./assets/images/10.png" alt="" />
            </div>
                  <h3 className="boxTitle">Visa Information Assistance</h3>
                 <p className='aboutMidBoxText'>Complete information is offered about the visa requirement and the process. All application status updates are also shared on a timely basis relieving you of any anxiety.</p>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 mb-5">
        <div className="tsServiceBox">
            <div className="iconStyle">
              <img loading="lazy" src="./assets/images/9.png" alt="" />
            </div>
                  <h3 className="boxTitle">Form Filling</h3>
                  <p className='aboutMidBoxText'>Apart from completing the application form, we check the validity and requirement of travel documents, photocopying of necessary documents and provide assistance.</p>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 mb-5">
        <div className="tsServiceBox">
            <div className="iconStyle">
              <img loading="lazy" src="./assets/images/11.png" alt="" />
            </div>
                  <h3 className="boxTitle">E-lodgement</h3>
                  <p className='aboutMidBoxText'>The e-lodgment center will facilitate electronic lodgment of applications and supporting documents for all forms of visa-related online applications</p>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 mb-5">
        <div className="tsServiceBox">
            <div className="iconStyle">
              <img loading="lazy" src="./assets/images/12.png" alt="" />
            </div>
                  <h3 className="boxTitle">E-Visa Application</h3>
                  <p className='aboutMidBoxText'>Get assistance in applying for visas for countries that offer Electronic Visas (E-Visa). E-Visa is your visa issued electronically. It serves as permission for traveling</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="info-container" className="infoContainer">
  <div className="container aboutSecBox">
    <div className="row">
    <div className="col-lg-4 col-md-6 mb-5 boxImage">
              <img src="./assets/single_image.jpg" alt="MyVisa Priority" style={{maxWidth:'100%'}}/>
        </div>
        <div className="col-lg-8 col-md-6 mb-5">
        <h3 className="secTitle">Our Mission</h3>
        <p className='aboutBoxText'>Our mission at MyVisaPriority.com  is to ensure to the clients that they receive  their new travel  adventures on  their bucket list, discover their happiness on  the trip,  and embark on their journey to explore the world .</p>

          <h3 className="secTitle">Why Choose Us</h3>
          <p className='aboutBoxText'>MyVisaPriority.com offers a simple greeting and charm that makes each client/guest feel special, and they guide them through every process of the visa services  for more than 120 countries. </p>
          <p className='aboutBoxText'>MyVisaPriority.com  is dedicated to providing the best experience possible for their clients/guests/, and they are always working to ensure that each and every one of their clients /guests has to get the wonderful priority services in time. MyVisaPriority.com is the perfect company for anyone looking for the best travel visa services.</p>
        </div>
    </div>
  </div>
</section>
<div className='infoContainer'></div>
<SocialButton/>
    </div>
  )
}

export default AboutPage