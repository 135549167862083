import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ServicePage from './pages/ServicePage';
import ContactPage from './pages/ContactPage';
import PrivacyPage from './pages/PrivacyPage';
import TermPage from './pages/TermPage';
import NavBar from './layouts/NavBar';
import NotFoundPage from './pages/NotFoundPage';
import FooterPage from './layouts/FooterPage';


function App() {
 // const [menuOpen,setMenuOpen] = useState(false);
  return (
    <Router>
      <NavBar/>
    <Routes>
         <Route path="/">
          <Route index  element={<HomePage/>}/>
          <Route path="/about" element={<AboutPage/>}/>
          <Route path="/services" element={<ServicePage/>}/>
          <Route path="/contact" element={<ContactPage/>}/> 
          <Route path="/privacy"  element={<PrivacyPage/>}/> 
          <Route path="/termcondition" element={<TermPage/>}/> 
          <Route  path="/notfound" element={<NotFoundPage/>}/> 
        </Route>
     </Routes>
     <FooterPage/>
  </Router>
  );
}

export default App;
