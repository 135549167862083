import '../scss/privacy.scss';
import SocialButton from '../components/SocialButton';

function TermPage() {

  return (
    <div  className="body-inner">
      <section id="main-container" className="infoContainer">
  <div className="container privacySecBox">
    <div className="row">
        <div className="col-lg-12 who-we-are">
          <h4 className="secTitle">PLEASE READ THESE TERMS AND CONDITIONS OF SERVICE BEFORE USING MYVISA PRIORITY</h4>
          <p className='boxText'>BY USING THIS SITE, BY SENDING OR DELIVERING YOUR PASSPORT(S), APPLICATION(S), OTHER DOCUMENT(S) TO MYVISA PRIORITY YOU ACKNOWLEDGE READING AND ACCEPTING THESE TERMS & CONDITIONS AND AGREE NOT TO VIOLATE OUR POLICIES. </p>
          <p className='boxText'>By using this site, you convey your agreement with these Terms & Conditions and the Privacy Statement. If you do not agree with the terms stated in this agreement or are dissatisfied with the site please direct your complaints to Contact Us page. Failure to comply with these Terms & Conditions may result in legal action and restriction of your ability to access this site. From time to time, MY VISA PRIORITY may modify these Terms & Conditions. Please continue to review this agreement whenever accessing or using this site.</p>
          <p className='boxText'><b>Refund Policy.</b> If client wishes to cancel the service they are entitled to apply for a refund. If your passport and other supporting documents were received by MYVISA PRIORITY and client for any reason requested for a cancellation no refund will be provided. In some cases, if MYVISA PRIORITY already shared the information regarding requirements, application forms and has explain the procedures to the client at the time that the cancellation was requested, an admin charge of 50% from the service charge will be retained by MYVISA PRIORITY. Upon cancelling or refunding any credit card paid order MYVISA PRIORITY reserves the right to deduct and retain all bank and handling charges occurred due to the order payment</p>
          <p className='boxText'>You may cancel by using Cancel/Delete function in your account when logged in or by calling our service line. Notwithstanding the foregoing, MYVISA PRIORITY cannot process refunds for Credit Card payments over 3 months old under any circumstances. In such cases MYVISA PRIORITY may issue a store credit applicable to future applications submitted through MYVISA PRIORITY. Credit cannot be applied to applications submitted through other agencies or applications submitted to Consulates directly.</p>
          <p className='boxText'><b>Processing time.</b> Processing time for visas quoted by MYVISA PRIORITY are approximate and based on processing times provided by Consulates under normal circumstances. Processing time should be calculated starting the next business day of the date when the documents are delivered to MYVISA PRIORITY. Consulates may request additional documents and may delay and/or deny processing at their discretion without further explanations. MYVISA PRIORITY will not be held responsible for any delays, cancellations, financial and other losses due to denial or delay in processing. No service fees, embassy fees, courier fees, shipping charges or any portion thereof will be refunded due to delays or denial of service.</p>
          <p className='boxText'><b>Fees and Payments.</b> You agree that your credit card(s) will be billed immediately after online verification takes place. You agree to pay all fees and charges for any products or services offered for sale by MYVISA PRIORITY or by any other vendor or service provider through MYVISA PRIORITY. You shall pay all applicable taxes and shipping charges relating to the purchase of any other products or services. Requirements and fees related to the processing of visas are subject to change, including, but not limited to, embassy consular fees, application forms and documentation required for visa issuance, which are subject to change without notice.</p>
          <p className='boxText'><b>Service description.</b> MYVISA PRIORITY does its best to correctly describe all services that appear on the website. However, MYVISA PRIORITY does not warrant that all the statements, definitions, and descriptions are most current, complete, accurate, and error free. Consulates often change their requirements and fees without prior warning and changes can take effect immediately. In an event, shall any description of service or any information on our site become outdated or inaccurate, you agree to hold MYVISA PRIORITY harmless. You will not hold MYVISA PRIORITY responsible for any and all inaccuracies or mistakes in service descriptions, prices, processing times, processing dates, and other information provided on the MYVISA PRIORITY website.</p>
          <p className='boxText'><b>Service.</b> Requirements and fees related to the processing of passports and visas are subject to change without notice. Application forms and additional documents required for visa issuance are subject to change without notice. Consular and U.S. Passport Agency officials may request additional documentation at their discretion. They may also decline issuance of travel documents, visa, or passport without disclosing the reason for denial. Processing time for visas and passports quoted by MYVISA PRIORITY are approximations based on normal circumstances, and are deemed reliable in most cases but are not guaranteed. MYVISA PRIORITY will not be held responsible for any delays, cancellations, financial and other losses due to denial or delay in processing. MYVISA PRIORITY reserves the right to decline processing of documents for any reason, including but not limited to incomplete documentation, insufficient processing time, or unusual circumstances.</p>
          <p className='boxText'><b>OFAC sanctioned countries.</b>In compliance with the banking laws of the UAE, MYVISA PRIORITY cannot provide any services related to any of OFAC sanctioned countries, namely: Belarus, Burma (Myanmar), Cote d’Ivoire (Ivory Coast), Cuba, Democratic Republic of Congo, Iran, Iraq, Liberia (former Liberian Regime of Charles Taylor), Lebanon, Libya, North Korea, Somalia, Sudan, Syria, Zimbabwe.</p>
          <p className='boxText'><b>Privacy.</b>Please direct your attention to our <b>Privacy Statement</b>, by which you also agree to be bound when using this site. Privacy Statement is a part of this agreement.</p>
          <p className='boxText'><b>Communications.</b>By using this site and our services you give MYVISA PRIORITY your express permission to contact you via email, messaging, or other electronic or non-electronic forms of communication for all purposes including distribution of marketing and promotional materials.</p>
          <p className='boxText'><b>Security.</b>By submitting registration and application forms, you certify that the information you provide is true and correct. You are responsible for maintaining the confidentiality of your User ID (login) and password. You are responsible for all uses of your User ID (login), whether or not authorized by you. You agree to notify us immediately of any unauthorized use of your User ID or password. Please refer to our Privacy Statement that governs submitted & distributed information.</p>
          <p className='boxText'><b>Intellectual Property.</b>This Web site, including but not limited to text, content, photographs, graphics, software applications, is protected as a collective work by copyrights, trademarks, service marks, international treaties and/or other proprietary rights and laws of the U.A.E, GCC Region and other countries. All logos, images, Web site design, text, graphics, software, HTML code used to generate Web pages on MYVISA PRIORITY, are the sole property of MYVISA PRIORITY and cannot be copied, adapted, sketched, painted, or otherwise reproduced in any manner whatsoever without the prior written permission of MYVISA PRIORITY. All other service marks, trademarks, names or logos, individual articles, columns and other elements are the property of their respective owners. MYVISA PRIORITY is not responsible for other service marks, trademarks, names or logos, articles, columns, and other elements that may be copyrighted or may be the trademarks and/or service marks of their respective owners.</p>
          <p className='boxText'><b>Copyrights and Trademarks compliance.</b>MYVISA PRIORITY takes all steps necessary to insure compliance with and honoring intellectual property rights of others. You hereby agree to hold MYVISA PRIORITY free of any responsibility and liability arising from any actual or alleged intellectual rights infringement and agree to notify MYVISA PRIORITY of any such actual or perceived infringement immediately upon discovery in writing. You also agree to allow MYVISA PRIORITY enough time to investigate and respond to any such infringement or accusation before taking any legal action.</p>
          <p className='boxText'><b>Indemnification.</b>You shall indemnify, hold and save harmless, and defend at your own expense MYVISA PRIORITY, its officers, directors, agents, and employees from and against all suits, claims, demands and liability of any nature or kind, including costs and expenses arising out of acts of violation of these Terms & Conditions by you, your employees, agents, sub-contractors, or anyone using your computer/user ID. This requirement shall extend to claims or liabilities of any third parties arising out of the use of any patented or copyrighted information or for any other reason. You agree to pay any and all costs, damages and expenses, including, but not limited to, reasonable attorneys' fees and costs awarded against or otherwise incurred by or in connection with or arising from any claim, suit and/or action or proceeding attributable to any such action.</p>
          <p className='boxText'><b>Termination.</b>You agree that MYVISA PRIORITY shall have the right to terminate your ability to access this site at any time without notice and you that will not hold MYVISA PRIORITY responsible or liable for any damages whatsoever. MYVISA PRIORITY reserves the right to decline processing of documents for any reason, including but not limited to incomplete documentation, insufficient processing time, or unusual circumstances.</p>
          <p className='boxText'><b>Links.</b>This web site contains links to third-party Web sites. These links are provided exclusively for information purposes and to assist in locating other Internet resources. We are not responsible for the content, accuracy of information, expressed opinions or statements, of any third-party sites linked to us or any links contained in a linked site.</p>
          <p className='boxText'><b>Restrictions on Use.</b>This site is intended for use by adult individuals over 18 years of age. It may not be used for any illegal purpose or in any manner inconsistent with these Terms & Conditions. You may not use this site for any reason other than its intended purpose. If you submit any information to our site, you agree not to post or transmit pyramid schemes or chain letters, a virus or any other harmful component, anything that violates the copyright or intellectual property rights of any person or entity, including but not limited to copyrights and trademarks, anything that is defamatory, abusive, libelous, unlawful, obscene, threatening, harassing, fraudulent, pornographic, harmful, or that could constitute or encourage conduct that would be considered a criminal offense, give rise to civil liability, or otherwise violate any law. YOU MAY NOT COPY, REPRODUCE, RECOMPILE, DECOMPILE, DISASSEMBLE, REVERSE ENGINEER, DISTRIBUTE, PUBLISH, DISPLAY, PERFORM, MODIFY, UPLOAD TO, CREATE DERIVATIVE WORKS FROM, RECIRCULATE, TRANSMIT OR IN ANY WAY EXPLOIT ANY PART OF THIS WEBSITE, IMAGES AND OTHER MATERIAL, OR OFFER ANY PART OF THE SERVICE FOR SALE, OR DISTRIBUTION WITHOUT THE PRIOR WRITTEN CONSENT OF MYVISA PRIORITY. Criminal or civil penalties may apply for inappropriate uses or disclosures of information on MYVISA PRIORITY.</p>
          <p className='boxText'><b>Disclaimer.</b>All the information on this web site is provided "as is" and without warranties, expressed or implied, as to the accuracy or completeness of the information, fitness for a particular use or purpose, including warranties of merchantability, title, non-infringement or otherwise. In no event shall MYVISA PRIORITY be responsible for any damages or losses whatsoever, direct or indirect, incidental or consequential, special or punitive, arising from or relating to the use or inability to use this web site. MYVISA PRIORITY hereby disclaims all warranties with regard to the hardware and software used to support this web site including all implied warranties, fitness for a particular purpose and incidental, special, direct or consequential damages. Accordingly, MYVISA PRIORITY, its officers and employees, partners, affiliates, subsidiaries, successors and assigns, and its third-party agents shall not, directly or indirectly, be liable, in any way, to you or any other person for any inaccuracies or errors in or omissions from the services including, but not limited to delays, errors or interruptions in the transmission or delivery of services, products, or loss, or damage arising for any reason from non-performance. MYVISA PRIORITY does not make any warranties or representations of any kind with respect to information, requirements, restrictions, quality, content, theme, style, and expressions provided directly or through any third party to this web site.</p>
          <p className='boxText'><b>Governing Law.</b>The laws of the United Arab Emirates shall govern this Agreement without giving effect to the choice of laws provisions thereof. Each party agrees to comply with all applicable laws in its performance of this Agreement.</p>
          <p className='boxText'><b>Entire Agreement.</b>This agreement along with the <b>Privacy Statement</b> constitute the entire agreement between you and MYVISA PRIORITY and supersedes any and all prior agreements, statements or representations regarding MYVISA PRIORITY. Failure to enforce any provision of this Agreement shall not be construed as a waiver of such provision or of the right to enforce such provision. No waiver, amendment, or other modification made by you shall be valid or binding unless in writing and signed by both parties.</p>
        </div>
    </div>
  </div>
</section>
<div className='infoContainer'></div>
<SocialButton/>
    </div>
  )
}

export default TermPage